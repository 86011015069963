@mixin amp-module-tabs-container {
    margin-bottom: -24px;

    .amp-module-tabs {
        z-index: 1;
        position: relative;

        .v-tabs-bar__content {
            padding-left: 24px;
        }

        .amp-module-tab {
            letter-spacing: normal;

            &.v-tab--active {
                color: $secondary-color;

                &.amp-module-mode-active {
                    color: $primary-color;
                    background-color: white;
                    box-shadow:
                        0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                        0px 1px 5px 0px rgba(0, 0, 0, 0.12);

                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;

                    &:before {
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                    }
                }
            }
        }
    }
}
