
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import '@/assets/sass/amp-module-tabs-container.scss';

.amp-module-tabs-container::v-deep {
    @include amp-module-tabs-container;
}
